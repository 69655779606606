/*jshint esversion: 11 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  ModalFooter,
  Alert,
  Input,
  Container,
  CardBody,
  Card,
  InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { isValidYouTubeLink } from '../../helpers/expression-checker';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getBatchExecutionList } from '../../api/batch';
import PageDataLoader from '../Utility/PageDataLoader';
import { addNewExecution } from '../../api/execution';
import FormUpload from '../../components/FormUpload';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useHistory } from 'react-router-dom';

const AddExecution = (props) => {
  const history = useHistory();
  const [images, setImages] = useState([]);
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [addExecutionRequestLoading, setAddExecutionRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });

  const [childOptions, setChildOptions] = useState([]);
  const [childOptionsLoading, setChildOptionsLoading] = useState(false);

  const [youtubeLink, setYoutubeLink] = useState('');
  const [ytLinks, setYTLinks] = useState('');

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setYoutubeLink(e.target.value);
  };


  const handleButtonClick = (index, link) => {
    resetErrorAlert();
    if (!isValidYouTubeLink(link)) {
      setRequestError({ show_alert: true, message: "Enter valid youtube link!" })
      return;
    }

    const trimmedLink = link.trim();
    if (trimmedLink !== '') {
      setExecutionArray(prevExecutionArray => {
        const newExecutionArray = [...prevExecutionArray];
        newExecutionArray[index]['links'] = [...newExecutionArray[index]['links'], link]
        return newExecutionArray;
      });
      setYoutubeLink('')
    }
  };



  const [executionArray, setExecutionArray] = useState([
    {
      activity: props.match.params.activityId,
      child: '',
      note: '',
      isPresent: true,
      images: [],
      links: []
    }
  ]);

  const [breadcrumbItems] = useState([
    { title: 'Batch', link: '/batches' },
    { title: 'Add Execution', link: '#' }
  ]);

  async function addExecution(event, values) {
    setLoading(true);
    resetErrorAlert();

    if (images && images.length > 0) {
      let newExecutionArray = [...executionArray];
      newExecutionArray[newExecutionArray.length - 1].images = images;
      setExecutionArray(newExecutionArray);
    }
    let nonMarked = executionArray.filter((k) => k.isPresent === null)
    if (nonMarked.length > 0) {
      showErrorAlert("Please mark attendance for child " + nonMarked[0].child.label)
      return
    }


    executionArray &&
      executionArray.map(async (e, i) => {
        let form = new FormData();
        form.set('activity', e.activity);
        form.set('child', e.child.value);
        form.set('note', e.note);
        form.set('isPresent', e.isPresent);
        form.set('links', e.links);
        form.set('draft', true);
        if (e.images.length > 0)
          e.images.map((g, index) => form.append(`images`, g));

        let response = await addNewExecution(form);
        setLoading(false);
        if (response) return;
      });

    setAddExecutionRequestLoading(false);
    setExecutionArray([
      {
        activity: props.match.params.activityId,
        child: '',
        note: '',
        isPresent: null,
        images: [],
        links: []
      }
    ]);
    showSuccessAlert('Execution added successfully');
  }

  async function publishExecution(event, values) {
    setLoading(true);
    resetErrorAlert();

    if (images && images.length > 0) {
      let newExecutionArray = [...executionArray];
      newExecutionArray[newExecutionArray.length - 1].images = images;
      setExecutionArray(newExecutionArray);
    }
    let nonMarked = executionArray.filter((k) => k.isPresent === null)
    if (nonMarked.length > 0) {
      showErrorAlert("Please mark attendance for child " + nonMarked[0].child.label)
      return
    }


    executionArray &&
      executionArray.map(async (e, i) => {
        let form = new FormData();
        form.set('activity', e.activity);
        form.set('child', e.child.value);
        form.set('note', e.note);
        form.set('isPresent', e.isPresent);
        form.set('links', e.links);
        form.set('draft', false);
        if (e.images.length > 0)
          e.images.map((g, index) => form.append(`images`, g));

        let response = await addNewExecution(form);
        setLoading(false);
        if (response) return;
      });

    setAddExecutionRequestLoading(false);
    setExecutionArray([
      {
        activity: props.match.params.activityId,
        child: '',
        note: '',
        isPresent: null,
        images: [],
        links: []
      }
    ]);
    showSuccessAlert('Execution added successfully');
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }
  let listElem = document.getElementById('batchList');
  let linkElem = document.getElementById('batchLink');

  useEffect(() => {
    if (listElem) listElem.classList.add('mm-active');
    if (linkElem) linkElem.classList.add('active');
    if (props.authUser && props.match.params.batchId) {
      fetchChildOptions();
    }
    // eslint-disable-next-line
  }, [props.authUser, listElem, linkElem]);

  const fetchChildOptions = async (current_page = 1, page_limit = 100) => {
    setChildOptionsLoading(true);
    const response = await getBatchExecutionList(
      props.match.params.activityId,
      current_page,
      page_limit
    );
    if (response) {
      setChildOptions(buildOptions(response?.data || []));
      setChildOptionsLoading(false);
    } else {
      setChildOptionsLoading(false);
      showErrorAlert(
        'Given date does not lie between batch start and end date'
      );
      return;
    }
  };

  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function handleChildSelection(index, selectedItem) {
    let newChildOptions = childOptions.filter((c) => c !== selectedItem);
    setChildOptions(newChildOptions);
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['child'] = selectedItem;
    setExecutionArray(newExecutionArray);
  }

  function handleNote(index, e) {
    resetErrorAlert();
    let value = e.target.value;
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['note'] = value;
    setExecutionArray(newExecutionArray);
  }

  function handleIsPresent(index, value) {
    resetErrorAlert();
    let newExecutionArray = [...executionArray];
    newExecutionArray[index]['isPresent'] =
      value;
    setExecutionArray(newExecutionArray);
  }

  const addExecutionFields = () => {
    resetErrorAlert();
    let newExecutionArray = [...executionArray];
    if (images && images.length > 0) {
      newExecutionArray[newExecutionArray.length - 1].images = images;
    }
    setImages([]);
    newExecutionArray.push({
      activity: props.match.params.activityId,
      child: '',
      note: '',
      isPresent: null,
      images: [],
      links: []
    });
    setExecutionArray(newExecutionArray);
  };

  let removeExecutionFields = (i) => {
    let newExecutionArray = [...executionArray];
    if (newExecutionArray[i]['child']) {
      let newChildOptions = childOptions;
      newChildOptions.push(newExecutionArray[i]['child']);
      setChildOptions(newChildOptions);
    }
    newExecutionArray.splice(i, 1);

    setExecutionArray(newExecutionArray);
  };

  const handleRemoveLink = (index, linkToRemove) => {
    setExecutionArray(prevExecutionArray => {
      const newExecutionArray = [...prevExecutionArray];

      // Filter out the link that matches the linkToRemove
      newExecutionArray[index]['links'] = newExecutionArray[index]['links'].filter(link => link !== linkToRemove);

      return newExecutionArray;
    });
  };


  return (
    <React.Fragment>
      <div className="page-content">
        {successAlert.show_alert && (
          <SweetAlert
            success
            title="Created"
            onConfirm={() => history.goBack()}
          >
            {successAlert.message}
          </SweetAlert>
        )}
        {requestError ? (
          <Alert
            color="danger"
            role="alert"
            isOpen={requestError.show_alert}
            toggle={resetErrorAlert}
          >
            <i className="mdi mdi-block-helper mr-2"></i>
            {requestError.message}
          </Alert>
        ) : null}
        {childOptionsLoading ? (
          <div className="d-flex justify-content-center">
            <PageDataLoader />
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title="Add Execution"
              breadcrumbItems={breadcrumbItems}
            />
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                >
                  {executionArray.map((element, index) => (
                    <>
                      <Row>
                        <Col md="5">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Label>Child</Label>
                                <Select
                                  options={childOptions}
                                  disabled={childOptionsLoading}
                                  onChange={(e) =>
                                    handleChildSelection(index, e)
                                  }
                                  value={element.child}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup>
                                <Label>Note</Label>
                                <AvField
                                  name="note"
                                  placeholder="Enter Note ..."
                                  type="textarea"
                                  rows="10"
                                  className="form-control"
                                  onChange={(e) => handleNote(index, e)}
                                  value={element.note}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup>
                                <Label>Attendance</Label>
                                <Select
                                  options={[{ "value": false, label: "absent" }, { "value": true, label: "present" }]}
                                  onChange={(e) => {
                                    handleIsPresent(index, e.value)
                                  }}
                                />

                                {/* <div
                                  className="custom-control custom-switch mb-2"
                                  dir="ltr"
                                >
                                  <Input
                                    id={'customSwitch' + index}
                                    type="checkbox"
                                    className="custom-control-input p-3"
                                  />
                                  <Label
                                    htmlFor={'customSwitch' + index}
                                    className="custom-control-label"
                                    onClick={(e) => handleIsPresent(index, e)}
                                  >
                                    Attendance Status
                                  </Label>
                                </div> */}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <FormUpload
                            setImages={setImages}
                            video={true}
                            title="Execution Images"
                          />
                          <InputGroup style={{ marginTop: '5px' }}>
                            <InputGroupAddon addonType='append'>
                              <InputGroupText>
                                <i className="fab fa-youtube"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder="Enter YouTube link"
                              value={youtubeLink}
                              onChange={handleInputChange}
                            />
                            <InputGroupAddon addonType="append">
                              <Button color="primary" onClick={() => handleButtonClick(index, youtubeLink)}>
                                +
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                          {executionArray[index]['links'].map(link => (
                            <p key={link} className="link-item" style={{ display: 'flex', alignItems: 'center' }}>
                              &#128279; {/* Unicode for link icon */}
                              <a href={link} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>{link}</a>
                              <span
                                onClick={() => handleRemoveLink(index, link)}
                                style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                              >
                                &#10060; {/* Unicode for cross icon */}
                              </span>
                            </p>
                          ))}
                        </Col>
                        <Col
                          md="1"
                          className="d-flex mt-1  justify-content-end align-items-center"
                        >
                          <Button
                            size="sm"
                            color="danger"
                            disabled={index === 0}
                            className=" waves-effect waves-light"
                            onClick={() => removeExecutionFields(index)}
                          >
                            <i className="ri-delete-bin-6-line align-middle"></i>
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  ))}
                  <Row className="mt-2 mb-2">
                    <Col className="d-flex justify-content-end align-items-center">
                      <Button
                        size="sm"
                        color="info"
                        className=" waves-effect waves-light"
                        disabled={
                          !executionArray[executionArray.length - 1].child ||
                          executionArray[executionArray.length - 1].note === ''
                        }
                        onClick={() => addExecutionFields()}
                      >
                        <i className="ri-add-line align-middle mr-2"></i>
                        Add
                      </Button>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      disabled={addExecutionRequestLoading}
                      type="button"
                      color="light"
                      onClick={() => history.goBack()}
                    >
                      Close
                    </Button>
                    <Button
                      onClick={addExecution}
                      color="success"
                      disabled={
                        !executionArray[executionArray.length - 1].child ||
                        executionArray[executionArray.length - 1].note === ''
                      }
                    >
                      {addExecutionRequestLoading
                        ? 'Adding ...'
                        : 'Add Execution'}
                    </Button>
                    <Button
                      onClick={publishExecution}
                      color="success"
                      disabled={
                        !executionArray[executionArray.length - 1].child ||
                        executionArray[executionArray.length - 1].note === ''
                      }
                    >
                      {addExecutionRequestLoading
                        ? 'Adding ...'
                        : 'Publish Execution'}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </CardBody>
            </Card>
          </Container>
        )}
      </div>
      {loading && <PageDataLoader />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(AddExecution);
