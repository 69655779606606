/*jshint esversion: 11 */
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { assignChildren, getBatchChildrenList } from '../../api/batch';
import { getChildList } from '../../api/child';
import { debounce } from 'lodash';

const AddChildModal = ({
  modalOpenState,
  closeModalHandler,
  fetchNewData,
  batchId,
  authUser
}) => {
  const [requestError, setRequestError] = useState({
    show_alert: false,
    message: ''
  });
  const [assignChildRequestLoading, setAssignChildRequestLoading] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState({
    message: '',
    show_alert: false
  });
  const [childOptions, setChildOptions] = useState([]);
  const [childOptionsLoading, setChildOptionsLoading] = useState(false);
  const [children, setChildren] = useState([]);

  async function handleAssignChild(event, values) {
    resetErrorAlert();

    // if (!children || children.length === 0) {
    //   showErrorAlert('Select atleast one child');
    //   return;
    // }
    let childrenArray = children && children.map((c) => c.value);
    setAssignChildRequestLoading(true);
    const { response, error } = await assignChildren(batchId, childrenArray);
    setAssignChildRequestLoading(false);
    if (error) {
      showErrorAlert(error);
      return;
    }
    closeModalHandler();
    showSuccessAlert(response.message);
    fetchNewData(batchId);
  }

  function showErrorAlert(message) {
    setRequestError({ message: message, show_alert: true });
  }

  function resetErrorAlert() {
    setRequestError({ message: '', show_alert: false });
  }

  function showSuccessAlert(message) {
    setSuccessAlert({ message: message, show_alert: true });
  }

  function resetSuccessAlert() {
    setSuccessAlert({ message: '', show_alert: false });
  }

  function resetState() {
    resetSuccessAlert();
    resetErrorAlert();
  }

  const fetchChildrenInfo = async () => {
    const childrenArray = await getBatchChildrenList(batchId, 1, 100);

    if (childrenArray) {
      setChildren(buildOptions(childrenArray.data));
    }
  };

  useEffect(() => {
    if (authUser && batchId) {
      fetchChildrenInfo();
      fetchChildOptions();
    }
    // eslint-disable-next-line
  }, [authUser, modalOpenState]);

  const fetchChildOptions = async (page,limit,searchParam) => {
    setChildOptionsLoading(true);
    const response = await getChildList(page,limit,searchParam && {title:searchParam});
    if (response) {
      setChildOptions(buildOptions(response?.data || []));
      setChildOptionsLoading(false);
    }
  };

  // const searchChild = async (e) => {
  //   setChildOptionsLoading(true);
  //   if (e.key.slice(0, 5) != "Arrow") {
  //     const response = await getChildList(1, 100, { filter_name: e.target.value });
  //     if (response) {
  //       setChildOptions(buildOptions(response?.data || []));
  //       setChildOptionsLoading(false);
  //     }
  //   }
  // };


  const searchChild = useCallback(
    debounce((event) => {
      fetchChildOptions(1, 50, event);
    }, 300), // Adjust the debounce delay as needed (300ms here)
    []
  );


  function buildOptions(list) {
    return list.map((item) => ({
      value: item._id,
      label: item.name
    }));
  }

  function handleChildSelection(selectedItem) {
    setChildren(selectedItem);
  }

  return (
    <React.Fragment>
      {successAlert.show_alert && (
        <SweetAlert success title="Updated" onConfirm={resetState}>
          {successAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={modalOpenState} backdrop="static" size="xl">
        <ModalHeader toggle={closeModalHandler}>
          {'Assign / Update Children'}
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            {requestError ? (
              <Alert
                color="danger"
                role="alert"
                isOpen={requestError.show_alert}
                toggle={resetErrorAlert}
              >
                <i className="mdi mdi-block-helper mr-2"></i>
                {requestError.message}
              </Alert>
            ) : null}
            <AvForm
              className="needs-validation"
              onValidSubmit={handleAssignChild}
            >
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Children</Label>
                    <Select
                      options={childOptions}
                      disabled={childOptionsLoading}
                      isMulti
                      defaultValue={children}
                      onChange={handleChildSelection}
                      onInputChange={searchChild}
                      isLoading={childOptionsLoading}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <ModalFooter>
                <Button
                  disabled={assignChildRequestLoading}
                  type="button"
                  color="light"
                  onClick={closeModalHandler}
                >
                  Close
                </Button>
                <Button type="submit" color="success">
                  {assignChildRequestLoading
                    ? 'Assigning ...'
                    : 'Assign Children'}
                </Button>
              </ModalFooter>
            </AvForm>
          </React.Fragment>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.Login?.user
  };
};

export default connect(mapStateToProps)(AddChildModal);
